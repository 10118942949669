import { handleActions } from 'redux-actions';

import { addEntities, updateObjectById } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';
import {
  readTracksFailed,
  readTracksSucceeded,
  tracksReceived,
} from '~/state/entities/tracks/actions';
import { ById, Track } from '~/typings/entities';

// if areLoaded is undefined, then a request for tracks is still processing
export type TrackState = ById<Track> & {
  areLoaded?: boolean;
};

const defaultState: TrackState = {
  byId: {},
};

export const tracks = handleActions(
  {
    [`${readTracksSucceeded}`]: (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        const newState = { ...state, areLoaded: true };

        return payload.reduce(
          (acc, curr) => updateObjectById(acc, curr.id, curr),
          newState
        );
      }
      return state;
    },
    [`${readTracksFailed}`]: (state) => ({ ...state, areLoaded: false }),
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.tracks) return state;
      const newState = { ...state, areLoaded: true };

      return addEntities(newState, payload.tracks.byId);
    },
    [`${tracksReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof tracksReceived>>
    ) => {
      const newTracks = payload.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {}
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newTracks,
        },
        error: null,
        areLoaded: true,
      };
    },
  },
  defaultState
);
