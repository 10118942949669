import { createAction } from 'redux-actions';
import request from 'superagent';

import { logger } from '~/libs/logging/logger';
import { csrf } from '~/libs/superagent-auth';
import { userAttributes } from '~/libs/userAttributes';
import { Dispatch } from '~/state/types';

import { PortalState, PortalThunkAction } from '../../types';
import { readScenesSucceeded } from '../actions';
import { selectPurposeChoices } from './selectors';
import { OnboardingData, Questionnaire } from './types';

export const sceneDataRequested = createAction(
  'accountSettings/SCENE_DATA_REQUESTED'
);

export const sceneDataFailed = createAction(
  'accountSettings/SCENE_DATA_FAILED'
);

export const fetchOnboardingQuestionnaire = (): Promise<Questionnaire> =>
  request
    .get('/onboarding_questionnaire')
    .use(csrf)
    .set('Accept', 'application/json')
    .then((response) => response.body);

export const loadOnboardingQuestionnaireData = (): PortalThunkAction => async (
  dispatch: Dispatch,
  getState: () => PortalState
) => {
  const state = getState();
  const onboardingPurposeChoices = selectPurposeChoices(state);

  if (!onboardingPurposeChoices) {
    dispatch(sceneDataRequested());
    try {
      const onboardingQuestionnaire = await fetchOnboardingQuestionnaire();
      dispatch(
        readScenesSucceeded({
          accountSettings: { onboardingQuestionnaire },
        })
      );
    } catch (error) {
      dispatch(sceneDataFailed({ error }));
    }
  }
};

// This is being used by the goal settings account page
export const saveOnboardingData = (data: OnboardingData) => async () => {
  const userId = userAttributes.id();
  const onboardingQuestionnaireCreateUrl = `/users/${userId}/onboarding_questionnaire`;

  try {
    await request
      .post(onboardingQuestionnaireCreateUrl)
      .send(data)
      .use(csrf)
      .set('X-User-Id', userId)
      .set('X-Auth-Token', userAttributes.authenticationToken());
  } catch (e) {
    logger.error({
      message: `Unable to save onboarding_questionnaire analytics data: ${e}`,
    });
  }
};
