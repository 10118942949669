import { handleActions } from 'redux-actions';

import {
  BusinessPlan,
  PlanAlertType,
} from '~/portal/scenes/BusinessAccountManagement/types';
import { planRoleSuccessMessage } from '~/portal/scenes/Enterprise/enterprisePlanStrings';
import { EnterprisePlanState } from '~/portal/scenes/Enterprise/types';

import {
  bulkCreateInvitationsFailed,
  bulkCreateInvitationsSucceeded,
  clearAlert,
  clearPlanUserSearchResults,
  createInvitationFailed,
  createInvitationSucceeded,
  createRegisterUrlFailed,
  createRegisterUrlSucceeded,
  deleteInvitationFailed,
  deleteInvitationSucceeded,
  deleteSeatFailed,
  deleteSeatSucceeded,
  fetchPlanError,
  fetchPlanInvitationsRequested,
  fetchPlanReceived,
  fetchPlanRequested,
  fetchPlanSeatsRequested,
  fetchPlansError,
  fetchPlansReceived,
  fetchPlansRequested,
  planInvitationsReceived,
  planSeatsReceived,
  resendInvitationFailed,
  resendInvitationSucceeded,
  searchedPlanUsersReceived,
  searchedPlanUsersRequested,
  searchedPlanUsersRequestError,
  setPlanAutoRenewError,
  setPlanAutoRenewRequested,
  setPlanAutoRenewSuccess,
  updateEnterpriseUsersNameError,
  updatePlanSeatRoleFailed,
  updatePlanSeatRoleRequested,
  updatePlanSeatRoleSucceeded,
} from './actions';

export const defaultState: EnterprisePlanState = {
  alert: undefined,
  planSeats: {
    loading: false,
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
    seats: [],
    sort: '',
    sortColumn: '',
  },
  planInvitations: {
    loading: false,
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
    invitations: [],
    sort: '',
    sortColumn: '',
  },
  plans: {
    loading: false,
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
    data: [],
    sort: '',
    sortColumn: '',
  },
  planSearchResults: {
    loading: false,
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
    users: [],
    sort: '',
    sortColumn: '',
  },
};

const updateAlert = (
  state: EnterprisePlanState,
  type: PlanAlertType,
  messages: string[]
) => ({
  ...state,
  alert: {
    type,
    messages,
  },
});

const updatePlan = (state: EnterprisePlanState, updatedPlan: BusinessPlan) => {
  const storePlans = state.plans.data;

  if (storePlans.find((plan) => plan.id === updatedPlan.id)) {
    return {
      ...state,
      plans: {
        ...state.plans,
        data: storePlans.map((plan) => {
          if (plan.id === updatedPlan.id) {
            return updatedPlan;
          }
          return plan;
        }),
      },
    };
  }

  return {
    ...state,
    plans: {
      ...state.plans,
      data: storePlans.concat([updatedPlan]),
    },
  };
};

export const enterprise = handleActions<EnterprisePlanState, {}>(
  {
    [`${clearAlert}`]: (state) => ({
      ...state,
      alert: undefined,
    }),

    [`${createInvitationSucceeded}`]: (state) =>
      updateAlert(state, 'success', ['Invitation created']),

    [`${bulkCreateInvitationsSucceeded}`]: (state) =>
      updateAlert(state, 'success', [
        'Your file was uploaded successfully. Pending invites may take a few minutes to load.',
      ]),

    [`${createRegisterUrlSucceeded}`]: (
      state,
      { payload }: Required<ReturnType<typeof createRegisterUrlSucceeded>>
    ) => {
      const { email, registerUrl } = payload;
      const msgs = [
        `Please share this invitation URL with ${email}:`,
        registerUrl,
        `Note: the URL sent in the initial invitation email is no longer valid.`,
      ];
      return updateAlert(state, 'success', msgs);
    },

    [`${resendInvitationSucceeded}`]: (state) =>
      updateAlert(state, 'success', ['Invitation has been resent']),

    [`${deleteSeatSucceeded}`]: (state) =>
      updateAlert(state, 'success', ['Seat deleted']),

    [`${deleteInvitationSucceeded}`]: (state) =>
      updateAlert(state, 'success', ['Invitation deleted']),

    [`${updatePlanSeatRoleRequested}`]: (state) => {
      return {
        ...state,
      };
    },

    [`${updatePlanSeatRoleSucceeded}`]: (
      state,
      { payload }: Required<ReturnType<typeof updatePlanSeatRoleSucceeded>>
    ) => {
      const { email, role } = payload;
      const successMessage = planRoleSuccessMessage(email, role);
      return updateAlert(state, 'success', [successMessage]);
    },

    [`${updatePlanSeatRoleFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof updatePlanSeatRoleFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not update role. Error: ${message}`,
      ]);
    },

    [`${createInvitationFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof createInvitationFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not create invitation. Error: ${message}`,
      ]);
    },

    [`${bulkCreateInvitationsFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof bulkCreateInvitationsFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not create invitations. Error: ${message}`,
      ]);
    },

    [`${resendInvitationFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof resendInvitationFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not send invitation. Error: ${message}`,
      ]);
    },

    [`${deleteInvitationFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof deleteInvitationFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not delete invitation. Error: ${message}`,
      ]);
    },

    [`${deleteSeatFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof deleteSeatFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not delete seat. Error: ${message}`,
      ]);
    },

    [`${createRegisterUrlFailed}`]: (
      state,
      { payload }: Required<ReturnType<typeof createRegisterUrlFailed>>
    ) => {
      const { message } = payload;
      return updateAlert(state, 'error', [
        `Could not generate register URL. Error: ${message}`,
      ]);
    },

    [`${fetchPlanInvitationsRequested}`]: (state) => {
      const { invitations } = state.planInvitations;
      return {
        ...state,
        planInvitations: {
          ...state.planInvitations,
          loading: invitations.length === 0,
        },
      };
    },

    [`${planInvitationsReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof planInvitationsReceived>>
    ) => {
      return {
        ...state,
        planInvitations: payload,
      };
    },

    [`${fetchPlanSeatsRequested}`]: (state) => {
      const { seats } = state.planSeats;
      return {
        ...state,
        planSeats: {
          ...state.planSeats,
          loading: seats.length === 0,
        },
      };
    },

    [`${planSeatsReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof planSeatsReceived>>
    ) => {
      return {
        ...state,
        planSeats: payload,
      };
    },

    [`${updateEnterpriseUsersNameError}`]: (
      state,
      { payload }: Required<ReturnType<typeof updateEnterpriseUsersNameError>>
    ) => {
      return updateAlert(state, 'error', [payload]);
    },

    [`${fetchPlansRequested}`]: (state) => {
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
        },
      };
    },

    [`${fetchPlansReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof fetchPlansReceived>>
    ) => {
      const { successMessage } = payload;
      return {
        ...state,
        plans: payload,
        ...(successMessage && {
          alert: {
            type: 'success',
            messages: [successMessage],
          },
        }),
      };
    },

    [`${fetchPlansError}`]: (
      state,
      { payload }: Required<ReturnType<typeof fetchPlansError>>
    ) => {
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          errorMessage: payload.message,
        },
        alert: {
          type: 'error',
          messages: [payload.message],
        },
      };
    },

    [`${fetchPlanRequested}`]: (state) => {
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
        },
      };
    },

    [`${fetchPlanReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof fetchPlanReceived>>
    ) => {
      return updatePlan(state, payload);
    },

    [`${fetchPlanError}`]: (
      state,
      { payload }: Required<ReturnType<typeof fetchPlansError>>
    ) => {
      return {
        ...state,
        errorMessage: payload.message,
        loading: false,
      };
    },

    [`${searchedPlanUsersRequested}`]: (state) => {
      return {
        ...state,
        planSearchResults: {
          ...state.planSearchResults,
          loading: true,
        },
      };
    },

    [`${searchedPlanUsersReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof searchedPlanUsersReceived>>
    ) => {
      return {
        ...state,
        planSearchResults: payload,
      };
    },

    [`${searchedPlanUsersRequestError}`]: (
      state,
      { payload }: Required<ReturnType<typeof searchedPlanUsersRequestError>>
    ) => {
      const searchResultNotLoadingState = {
        ...state,
        planSearchResults: {
          ...state.planSearchResults,
          loading: false,
        },
      };

      return updateAlert(searchResultNotLoadingState, 'error', [
        payload.message,
      ]);
    },

    [`${clearPlanUserSearchResults}`]: (state) => {
      return {
        ...state,
        planSearchResults: {
          ...state.planSearchResults,
          users: [],
          perPage: 0,
          total: 0,
          totalPages: 0,
        },
      };
    },
    [`${setPlanAutoRenewRequested}`]: (state) => {
      return {
        ...state,
      };
    },
    [`${setPlanAutoRenewSuccess}`]: (
      state,
      { payload }: Required<ReturnType<typeof setPlanAutoRenewSuccess>>
    ) => {
      return updatePlan(state, payload);
    },
    [`${setPlanAutoRenewError}`]: (
      state,
      { payload }: Required<ReturnType<typeof setPlanAutoRenewError>>
    ) => {
      return updateAlert(state, 'error', [
        `Could not toggle plan's auto-renew. Error: ${payload.message}`,
      ]);
    },
  },
  defaultState
);
