import { createSelector } from 'reselect';

import { TYPE_HEADERS } from '~/portal/scenes/explore/SortingQuiz/Results/typeData';
import {
  SortingQuizArchetype,
  SortingQuizState,
} from '~/portal/state/scenes/sortingQuiz/types';
import { selectLocationQuery } from '~/state/location/selectors';

import { PortalState } from '../../types';

export const selectSortingQuiz = (state: PortalState) =>
  state.scenes.sortingQuiz;

export const selectSortingQuizQuestions = (state: PortalState) =>
  state.scenes.sortingQuiz.questions;

export const selectResultsFromQueryParams = createSelector(
  selectLocationQuery,
  (query) => {
    const results: [SortingQuizArchetype, number][] = [];

    Object.keys(TYPE_HEADERS).forEach((archetype: SortingQuizArchetype) => {
      results.push([archetype, Number(query[archetype] || 0)]);
    });

    return resultSorter(results);
  }
);

export const selectResultsFromQuizAnswers = (
  questions: SortingQuizState['questions'],
  answers: SortingQuizState['answers']
) => {
  const results = answers.reduce(
    (accum, answer, index) => {
      if (answer === null) {
        return accum;
      }

      const answerData = questions[index].answers[answer];
      Object.keys(accum).forEach((resultType: SortingQuizArchetype) => {
        accum[resultType] += answerData[resultType];
      });
      return accum;
    },
    { questioner: 0, solver: 0, connector: 0, advocator: 0 }
  );
  const numberOfAnswers = answers.filter((a) => a !== null).length;
  Object.keys(results).forEach((key: SortingQuizArchetype) => {
    results[key] = Math.ceil((results[key] / numberOfAnswers) * 100) || 0;
  });

  return resultSorter(
    Object.entries(results) as [SortingQuizArchetype, number][]
  );
};

const resultSorter = (results: [SortingQuizArchetype, number][]) => {
  results.sort((a, b) => {
    if (b[1] > a[1]) {
      return 1;
    }
    if (b[1] < a[1]) {
      return -1;
    }
    const TIEBREAK_ORDERING = [
      'solver',
      'connector',
      'advocator',
      'questioner',
    ];
    return TIEBREAK_ORDERING.indexOf(a[0]) - TIEBREAK_ORDERING.indexOf(b[0]);
  });
  return results;
};
