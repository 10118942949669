import { progressSummaryRequest } from '~/libs/progressSummaryRequest';
import { readEntitiesSucceeded } from '~/state/entities/actions';
import { selectContentItemProgress } from '~/state/entities/contentItemProgresses/selectors';
import { ThunkAction } from '~/state/types';

export function readContentItemProgressesRequested(
  ids: string[],
  { bustCache = false } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    const contentItemProgressesToFetch = bustCache
      ? ids
      : ids.filter((id) => !selectContentItemProgress(getState(), id));

    if (!contentItemProgressesToFetch.length) return;

    const body = await progressSummaryRequest.contentItems(ids);

    dispatch(
      readEntitiesSucceeded({
        contentItemProgresses: body.content_item_progresses,
      })
    );
  };
}
