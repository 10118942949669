import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { ProgressPayload, ProgressState } from './types';

export const progress = handleActions<ProgressState, ProgressPayload>(
  {
    [`${actions.loadProgressData}`]: (
      state,
      action: Required<ReturnType<typeof actions.loadProgressData>>
    ) => {
      return {
        ...state,
        ...action.payload.progress,
      };
    },
  },
  {}
);
