import { handleActions } from 'redux-actions';

import { pathSceneLoaded } from './actions';

export interface PathSceneState {
  isLoaded: boolean;
}

const defaultState: PathSceneState = {
  isLoaded: false,
};

export const paths = handleActions<PathSceneState, {}>(
  {
    [`${pathSceneLoaded}`]: (state) => {
      return {
        ...state,
        isLoaded: true,
      };
    },
  },
  defaultState
);
