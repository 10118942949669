import { createAction } from 'redux-actions';
import request from 'superagent';

import { csrf, userAuth } from '~/libs/superagent-auth';
import { PortalState, PortalThunkAction } from '~/portal/state/types';
import { selectCurrentUserId } from '~/state/currentUser/selectors';
import { readEntitiesSucceeded } from '~/state/entities/actions';
import { Dispatch } from '~/state/types';
import { ContentItem, Module, Path, Track } from '~/typings/entities';

import { selectDashboardResume } from './selectors';

export const resumeContentReceived = createAction(
  'scenes/dashboard/resume/CONTENT_RECEIVED'
);

export const getSecondaryResumeDataSucceeded = createAction(
  'resume/GET_SECONDARY_RESUME_DATA_SUCCEEDED'
);

export function createContainerStructure(
  container: Path | Track | Module | undefined
) {
  return container
    ? {
        byId: {
          [container.id]: container,
        },
      }
    : null;
}

export const resumeContentRequested = (): PortalThunkAction => async (
  dispatch,
  getState
) => {
  const state = getState();
  const resumeState = selectDashboardResume(state);
  if (resumeState?.content_item_id) {
    return;
  }

  const { path, track, module } = await getDashboardResumeContent(
    dispatch,
    getState
  );

  await dispatch(
    readEntitiesSucceeded({
      paths: createContainerStructure(path),
      tracks: createContainerStructure(track),
      modules: createContainerStructure(module),
    })
  );
};

export interface ResumeContentData {
  track: Track;
  path: Path;
  module: Module;
  content_item: ContentItem;
  content_item_id: string;
}

async function getDashboardResumeContent(
  dispatch: Dispatch,
  getState: () => PortalState
): Promise<ResumeContentData> {
  const state = getState();
  const userId = selectCurrentUserId(state);

  const result = (
    await request
      .get(`/content_resume/${userId}`)
      .use(csrf)
      .use(userAuth)
      .set('Accept', 'application/json')
  ).body;
  dispatch(resumeContentReceived(result));
  return result;
}
