export const arrayToHash = <T>(
  arr: T[],
  keyProperty: keyof T
): { [key: string]: T } => {
  if (!Array.isArray(arr)) {
    throw new Error(`arrayToHash: Input must be an array`);
  }

  if (!arr.length) return {};

  if (!keyProperty) {
    throw new Error(
      `arrayToHash: Requires a key property to use as the object keys`
    );
  }

  return arr.reduce<Record<string, T>>((acc, item) => {
    const key = item[keyProperty] as any;

    if (!key) return acc;

    acc[key] = item;
    return acc;
  }, {});
};
