import { cloneDeep, keyBy, omit } from 'lodash';
import { Action, handleActions } from 'redux-actions';

import {
  fetchNotificationsSucceeded,
  notificationsAreaDeregistered,
  notificationsAreaRegistered,
  notificationsDeleted,
  notificationsMarkedRead,
  pollNotificationsStarted,
  pollNotificationsStopped,
} from './actions';
import {
  DeregisterPayload,
  FetchSuccessPayload,
  MarkReadPayload,
  NotificationsPayload,
  NotificationsState,
  RegisterPayload,
} from './types';

export const INITIAL_STATE: NotificationsState = {
  polling: false,
  entities: {},
  areas: {},
  baseUrl: '',
};

export const notifications = handleActions<
  NotificationsState,
  NotificationsPayload
>(
  {
    [`${pollNotificationsStarted}`]: (state) => ({
      ...state,
      polling: true,
    }),
    [`${pollNotificationsStopped}`]: (state) => ({
      ...state,
      polling: false,
    }),
    [`${notificationsAreaRegistered}`]: (
      state,
      { payload }: Action<RegisterPayload>
    ) => {
      if (!payload) {
        return state;
      }

      return {
        ...state,
        areas: {
          ...state.areas,
          [payload.id]: payload,
        },
      };
    },
    [`${notificationsAreaDeregistered}`]: (
      state,
      { payload }: Action<DeregisterPayload>
    ) => {
      if (!payload) {
        return state;
      }

      const {
        areas: { [payload]: removedArea, ...nextAreas },
      } = state;
      return {
        ...state,
        areas: nextAreas,
      };
    },
    [`${fetchNotificationsSucceeded}`]: (
      state,
      { payload }: Action<FetchSuccessPayload>
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...keyBy(payload, 'id'),
        },
      };
    },
    [`${notificationsDeleted}`]: (
      state,
      { payload }: Action<MarkReadPayload>
    ) => {
      if (!payload) {
        return state;
      }

      return {
        ...state,
        entities: omit(state.entities, payload),
      };
    },
    [`${notificationsMarkedRead}`]: (
      state,
      { payload }: Action<MarkReadPayload>
    ) => {
      if (!payload) {
        return state;
      }

      const newEntities = cloneDeep(state.entities);

      payload.forEach((id) => {
        newEntities[id].status = 0;
      });

      return {
        ...state,
        entities: newEntities,
      };
    },
  },
  INITIAL_STATE
);
