import React from 'react';

import { SortingQuizArchetypeOptions } from '~/portal/state/scenes/sortingQuiz/types';

import { AdvocatorSvg } from './images/advocator';
import { ConnectorSvg } from './images/connector';
import { QuestionerSvg } from './images/questioner';
import { SolverSvg } from './images/solver';

export const TYPE_HEADERS: SortingQuizArchetypeOptions = {
  questioner: 'Question Asker',
  solver: 'Problem Solver',
  connector: 'Dot Connector',
  advocator: 'User Advocate',
};

export const TYPE_IMAGES = {
  questioner: (className: string) => <QuestionerSvg className={className} />,
  connector: (className: string) => <ConnectorSvg className={className} />,
  solver: (className: string) => <SolverSvg className={className} />,
  advocator: (className: string) => <AdvocatorSvg className={className} />,
};

export const THEME_COLORS: SortingQuizArchetypeOptions = {
  questioner: 'orangeTheme',
  solver: 'pinkTheme',
  connector: 'blueTheme',
  advocator: 'greenTheme',
};

export const MATCH_TYPE = [
  'Most Like:',
  'A Lot Like:',
  'Also Like:',
  'A Little Like:',
];
