import React from 'react';

export interface QuestionerSvgProps {
  className?: string;
}

export const QuestionerSvg = ({ className }: QuestionerSvgProps) => (
  <svg className={className} viewBox="0 0 48 48" aria-hidden="true">
    <g strokeWidth="1" fillRule="evenodd">
      <path
        d="M24,2.23606798 L1.61803399,47 L46.381966,47 L24,2.23606798 Z"
        strokeWidth="2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
