import { handleActions } from 'redux-actions';

import { addEntities, updateObjectById } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';

import { readContentItemsSucceeded } from './actions';

const defaultState = {
  byId: {},
};

export const contentItems = handleActions(
  {
    [`${readContentItemsSucceeded}`]: (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        return payload.reduce(
          (acc, curr) => updateObjectById(acc, curr.id, curr),
          state
        );
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          ...payload,
        },
      };
    },
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.contentItems) return state;
      return addEntities(state, payload.contentItems.byId, { idKey: 'id' });
    },
  },
  defaultState
);
