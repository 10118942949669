import { keyBy } from 'lodash';
import { handleActions } from 'redux-actions';

import { addEntities, updateObjectById } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';
import { readModulesSucceeded } from '~/state/entities/modules/actions';

import { moduleReviewCardsReceived, modulesReceived } from './actions';
import { ModuleState } from './types';

const defaultState: ModuleState = {
  byId: {},
  bySlug: {},
  reviewCards: {},
};

export const modules = handleActions(
  {
    [`${modulesReceived}`]: (state, action: any) => {
      const { payload } = action;
      const newModules = keyBy(payload, (m) => m.id);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newModules,
        },
      };
    },
    [`${moduleReviewCardsReceived}`]: (
      state,
      action: Required<ReturnType<typeof moduleReviewCardsReceived>>
    ) => {
      const { payload } = action;
      const { moduleId, reviewCards } = payload;

      return {
        ...state,
        reviewCards: {
          ...state.reviewCards,
          [moduleId]: reviewCards.map((reviewCard) => reviewCard?.id),
        },
      };
    },

    [`${readModulesSucceeded}`]: (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        return payload.reduce(
          (acc, curr) => updateObjectById(acc, curr.id, curr),
          state
        );
      }
      return state;
    },

    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.modules) return state;
      return addEntities(state, payload.modules.byId);
    },
  },
  defaultState
);
