import { handleActions } from 'redux-actions';

import { addEntities } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';

export const courseProgresses = handleActions(
  {
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.courseProgresses) return state;
      return addEntities(state, payload.courseProgresses.bySlug, {
        idKey: 'slug',
      });
    },
  },
  {}
);
