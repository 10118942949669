import { createAction } from 'redux-actions';

import { graphqlGatewayRequest } from '~/libs/graphqlGatewayRequest';
import { arrayToHash } from '~/libs/state/arrayToHash';
import { selectGraphQLGatewayBaseUrl } from '~/state/apiConfig/selectors';
import { selectUserJWT } from '~/state/currentUser/selectors';
import { SiteThunkAction } from '~/state/types';
import { ContentItem } from '~/typings/entities';

import { queryContentItemsByIdsOrSlugs } from './queries';
import { selectAllContentItems, selectContentItemBySlug } from './selectors';

export type ContentItemsById = Record<string, ContentItem>;

export type ReadContentItemsSucceededPayload = ContentItemsById | ContentItem[];

export const readContentItemsSucceeded = createAction<ReadContentItemsSucceededPayload>(
  'entities/READ_CONTENT_ITEMS_SUCCEEDED'
);

export interface ReadContentItemsRequested {
  ids: string[];
  minimal?: boolean;
}

export interface ContentServiceV4ContentItemsResponse {
  contentItems: ContentItem[];
}

export function readContentItemsRequested({
  ids,
  minimal = true,
}: ReadContentItemsRequested): SiteThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const contentItemIdsToFetch = ids.filter(
      (id) => !selectAllContentItems(state)[id]
    );

    if (contentItemIdsToFetch.length === 0) return;

    const jwt = selectUserJWT(state);
    const url = selectGraphQLGatewayBaseUrl(state);

    const { contentItems } = (await graphqlGatewayRequest({
      jwt,
      url,
      query: queryContentItemsByIdsOrSlugs,
      variables: {
        ids: contentItemIdsToFetch,
        slugs: null,
        minimal,
      },
    })) as ContentServiceV4ContentItemsResponse;

    // Fetch failures would have been logged & handled above
    if (!contentItems) {
      return;
    }

    const contentItemHash = arrayToHash(contentItems, 'id');
    dispatch(readContentItemsSucceeded(contentItemHash));
  };
}

export interface ContentItemRequestBySlug {
  minimal?: boolean;
  relations?: boolean;
  slug: string;
}

export function contentItemRequestedBySlug(
  { slug, minimal = true }: ContentItemRequestBySlug,
  { useCache = true }
): SiteThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    if (!slug)
      throw new Error(
        'contentItemRequestedBySlug: required slug param not found'
      );
    if (useCache && selectContentItemBySlug(state, slug)) return;

    const jwt = selectUserJWT(state);

    const url = selectGraphQLGatewayBaseUrl(state);
    const { contentItems } = (await graphqlGatewayRequest({
      jwt,
      url,
      query: queryContentItemsByIdsOrSlugs,
      variables: {
        ids: null,
        slugs: [slug],
        minimal,
      },
    })) as ContentServiceV4ContentItemsResponse;

    const contentItemHash = arrayToHash(contentItems, 'id');
    dispatch(readContentItemsSucceeded(contentItemHash));
  };
}
