import { routesMap } from '~/portal/routes';
import rootReducer, { reduceInitialState } from '~/portal/state/reducers';
import rootSaga from '~/portal/state/sagas';
import { PortalState } from '~/portal/state/types';
import initializeStore, {
  ConfigureStoreOptions,
} from '~/store/initializeStore';

export function configureStore(
  initialState: PortalState,
  { requestPath }: Pick<ConfigureStoreOptions, 'requestPath'>
) {
  return initializeStore({
    initialState,
    requestPath,
    reduceInitialState,
    routesMap,
    rootReducers: rootReducer,
    rootSaga,
    hotReloadPath: 'portal/state/reducers',
  });
}
