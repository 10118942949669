import { createAction } from 'redux-actions';

import { fetchBusinessPlansUrl, paginationUrl } from '~/libs/urlHelpers';
import {
  BusinessPlan,
  FetchPlanErrorPayload,
  PlanInvitationsState,
  PlanSeatsState,
  PlansState,
} from '~/portal/scenes/BusinessAccountManagement/types';
import {
  EnterprisePlanErrorPayload,
  EnterpriseSeatSuccessPayload,
} from '~/portal/scenes/Enterprise/types';
import { PortalThunkAction } from '~/portal/state/types';

export const fetchPlansRequested = createAction(
  'enterprise/FETCH_PLANS_REQUESTED'
);
export const fetchPlansReceived = createAction<PlansState>(
  'enterprise/FETCH_PLANS_RECEIVED'
);
export const fetchPlansError = createAction<FetchPlanErrorPayload>(
  'enterprise/FETCH_PLANS_ERROR'
);

export const fetchPlanRequested = createAction(
  'enterprise/FETCH_PLAN_REQUESTED'
);
export const fetchPlanReceived = createAction<BusinessPlan>(
  'enterprise/FETCH_PLAN_RECEIVED'
);
export const fetchPlanError = createAction<FetchPlanErrorPayload>(
  'enterprise/FETCH_PLAN_ERROR'
);

export const fetchPlanSeatsRequested = createAction(
  'enterprise/PLAN_SEATS_REQUESTED'
);
export const planSeatsReceived = createAction<PlanSeatsState>(
  'enterprise/PLAN_SEATS_RECEIVED'
);
export const fetchPlanInvitationsRequested = createAction(
  'enterprise/PLAN_INVITATIONS_REQUESTED'
);
export const planInvitationsReceived = createAction<PlanInvitationsState>(
  'enterprise/PLAN_INVITATIONS_RECEIVED'
);

export const deleteSeatSucceeded = createAction(
  'enterprise/DELETE_SEAT_SUCCEEDED'
);
export const deleteSeatFailed = createAction<EnterprisePlanErrorPayload>(
  'enterprise/DELETE_SEAT_FAILED'
);
export const updatePlanSeatRoleRequested = createAction(
  'enterprise/UPDATE_SEAT_REQUESTED'
);
export const updatePlanSeatRoleSucceeded = createAction<EnterpriseSeatSuccessPayload>(
  'enterprise/UPDATE_SEAT_SUCCEEDED'
);
export const updatePlanSeatRoleFailed = createAction(
  'enterprise/UPDATE_SEAT_FAILED'
);

export const deleteInvitationSucceeded = createAction(
  'enterprise/DELETE_INVITATION_SUCCEEDED'
);
export const deleteInvitationFailed = createAction<EnterprisePlanErrorPayload>(
  'enterprise/DELETE_INVITATION_FAILED'
);

export const createInvitationSucceeded = createAction(
  'enterprise/CREATE_INVITATION_SUCCEEDED'
);
export const createInvitationFailed = createAction<EnterprisePlanErrorPayload>(
  'enterprise/CREATE_INVITATION_FAILED'
);

export const bulkCreateInvitationsSucceeded = createAction(
  'enterprise/BULK_CREATE_INVITATIONS_SUCCEEDED'
);
export const bulkCreateInvitationsFailed = createAction<EnterprisePlanErrorPayload>(
  'enterprise/BULK_CREATE_INVITATIONS_FAILED'
);

export const resendInvitationRequested = createAction(
  'enterprise/RESEND_INVITATION_REQUESTED'
);
export const resendInvitationSucceeded = createAction(
  'enterprise/RESEND_INVITATION_SUCCEEDED'
);
export const resendInvitationFailed = createAction<EnterprisePlanErrorPayload>(
  'enterprise/RESEND_INVITATION_FAILED'
);

export const createRegisterUrlSucceeded = createAction(
  'enterprise/CREATE_REGISTER_URL_SUCCEEDED'
);
export const createRegisterUrlFailed = createAction<EnterprisePlanErrorPayload>(
  'enterprise/CREATE_REGISTER_URL_FAILED'
);

export const clearAlert = createAction('enterprise/CLEAR_ALERT');

export const searchedPlanUsersRequested = createAction(
  'enterprise/SEARCHED_PLAN_USERS_REQUESTED'
);
export const searchedPlanUsersReceived = createAction(
  'enterprise/SEARCHED_PLAN_USERS_RECEIVED'
);
export const searchedPlanUsersRequestError = createAction<EnterprisePlanErrorPayload>(
  'enterprise/SEARCHED_PLAN_USERS_REQUEST_ERROR'
);
export const clearPlanUserSearchResults = createAction(
  'enterprise/CLEAR_PLAN_USER_SEARCH_RESULTS'
);

export const updateEnterpriseUsersNameError = createAction(
  'enterprise/UPDATE_USERS_NAME_ERROR'
);

export const setPlanAutoRenewRequested = createAction(
  'enterprise/SET_PLAN_AUTO_RENEW_REQUESTED'
);

export const setPlanAutoRenewSuccess = createAction<BusinessPlan>(
  'enterprise/SET_PLAN_AUTO_RENEW_SUCCESS'
);

export const setPlanAutoRenewError = createAction<EnterprisePlanErrorPayload>(
  'enterprise/SET_PLAN_AUTO_RENEW_ERROR'
);

export const errorFromResponse = async (response: Response) => {
  const text = await response.text();
  const body = JSON.parse(text);
  return body.errors[0].description;
};

export const fetchPlans = (
  page: number,
  sort?: string,
  sortColumn?: string,
  planStatus?: string,
  planTier?: string,
  planType?: string,
  searchParam?: string,
  isSearch?: boolean,
  isInternalPlan?: boolean
): PortalThunkAction => async (dispatch) => {
  dispatch(fetchPlansRequested());

  const invalidFilterErrorMsg = 'Could not find a match using the filters.';
  const successMessage = isSearch ? 'Search successful' : '';

  const url = paginationUrl(fetchBusinessPlansUrl, {
    page,
    sort,
    sort_column: sortColumn,
    plan_status: planStatus,
    plan_tier: planTier,
    plan_type: planType,
    custom_name: searchParam,
    is_internal_plan: isInternalPlan,
  });
  try {
    const response = await fetch(url).then((r) => r.json());

    const { data, meta, error } = response;

    if (error) {
      dispatch(fetchPlansError({ message: error.message }));
      return;
    }

    const {
      page,
      per_page: perPage,
      total,
      total_pages: totalPages,
      sort,
      sort_column: sortColumn,
    } = meta;

    const payload = {
      loading: false,
      page,
      perPage,
      total,
      totalPages,
      data,
      sort,
      sortColumn,
      successMessage,
    };

    if (data.length === 0) {
      dispatch(
        fetchPlansError({
          message: invalidFilterErrorMsg,
        })
      );
    }
    dispatch(fetchPlansReceived(payload));
  } catch (error) {
    dispatch(fetchPlansError({ message: error.message }));
  }
};
