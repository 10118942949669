import { without } from 'lodash';
import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { ModalsState } from './types';

const defaultState: ModalsState = {
  entities: {},
  ids: [],
};

export const modals = handleActions<ModalsState, {}>(
  {
    [`${actions.showModal}`]: (
      state,
      {
        payload: { type, id, ...props },
      }: Required<ReturnType<typeof actions.showModal>>
    ) => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          id,
          type,
          props,
        },
      },
      ids: [...state.ids, id],
    }),
    [`${actions.hideModal}`]: (
      state,
      { payload: id }: Required<ReturnType<typeof actions.hideModal>>
    ) => {
      const entities = { ...state.entities };
      delete entities[id];
      const ids = without(state.ids, id);
      return {
        ...state,
        entities,
        ids,
      };
    },
    [`${actions.hideCurrentModal}`]: (state) => {
      const entities = { ...state.entities };
      delete entities[state.ids[0]];
      const ids = state.ids.slice(1);
      return {
        ...state,
        entities,
        ids,
      };
    },
  },
  defaultState
);
