import { handleActions } from 'redux-actions';

import { addEntities } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';

import { readCoursesSucceeded } from './actions';

const defaultState: {
  bySlug?: {};
} = {};

export const courses = handleActions(
  {
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.courses) return state;
      return addEntities(state, payload.courses.bySlug, { idKey: 'slug' });
    },

    [`${readCoursesSucceeded}`]: (state, { payload }) =>
      addEntities(state, payload, { idKey: 'slug' }),
  },
  defaultState
);
