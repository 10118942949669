import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { ContainerProgressState, ProgressLoadedPayload } from './types';

const defaultState: ContainerProgressState = {
  modules: {},
  tracks: {},
  paths: {},
  journeys: {},
};

export const containerProgresses = handleActions<
  ContainerProgressState,
  ProgressLoadedPayload
>(
  {
    [`${actions.progressLoaded}`]: (
      state,
      action: Required<ReturnType<typeof actions.progressLoaded>>
    ) => {
      const { type, id, progress } = action.payload;

      return {
        ...state,
        [type]: {
          ...state[type],
          [id]: progress,
        },
      };
    },
  },
  defaultState
);
