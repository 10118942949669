import { handleActions } from 'redux-actions';

import { addEntities, updateObjectById } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';
import {
  readPathsFailed,
  readPathsSucceeded,
} from '~/state/entities/paths/actions';
import { ById } from '~/typings/entities';
import { Path } from '~/typings/entities/path';

// if areLoaded is undefined, then a request for paths is still processing
export type PathState = ById<Path> & {
  areLoaded?: boolean;
};

const defaultState: PathState = {
  byId: {},
};

export const paths = handleActions(
  {
    [`${readPathsSucceeded}`]: (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        const newState = { ...state, areLoaded: true };

        return payload.reduce(
          (acc, curr) => updateObjectById(acc, curr.id, curr),
          newState
        );
      }
      return state;
    },
    [`${readPathsFailed}`]: (state) => ({
      ...state,
      areLoaded: false,
    }),
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.paths) return state;

      const newState = { ...state, areLoaded: true };
      return addEntities(newState, payload.paths.byId);
    },
  },
  defaultState
);
