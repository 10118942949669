import { alerts } from './alerts/reducers';
import { apiConfig } from './apiConfig/reducers';
import { brandedBanner } from './brandedBanner/reducers';
import { butterbar } from './butterbar/reducers';
import { catalogNavLinks } from './catalogNavLinks/reducers';
import { contentful } from './contentful/reducers';
import { currentUser } from './currentUser/reducers';
import { features } from './features/reducers';
import { modals } from './modals/reducers';
import { notifications } from './notifications/reducers';
import { payment } from './payment/reducers';
import { proExclusive } from './proExclusive/reducers';
import { releasedContent } from './releasedContent/reducers';

const staticReducer = (state = {}) => state;

export const siteReducers = {
  alerts,
  apiConfig,
  brandedBanner,
  butterbar,
  catalogNavLinks,
  contentful,
  currentUser,
  entities: staticReducer,
  features,
  modals,
  notifications,
  payment,
  proExclusive,
  releasedContent,
};
