import { createAction } from 'redux-actions';

export const achievements = createAction('location/ACHIEVEMENTS');
export const account = createAction('location/ACCOUNT');
export const authorizeOauth = createAction('location/AUTHORIZE_OAUTH');
export const businessTeamsQuote = createAction('location/BUSINESS_TEAMS_QUOTE');

export const confirmEmail = createAction('location/CONFIRM_EMAIL');
export const cookiePolicy = createAction('location/COOKIE_POLICY');
export const course = createAction('location/COURSE');
export const enrolledCourseSyllabus = createAction(
  'location/ENROLLED_COURSE_SYLLABUS'
);
export const dashboardResume = createAction('location/DASHBOARD_RESUME');
export const enterpriseRegistration = createAction(
  'location/ENTERPRISE_REGISTRATION'
);
export const enterprisePlan = createAction('location/ENTERPRISE_PLAN');
export const enterprisePlans = createAction('location/ENTERPRISE_PLANS');
export const errorBrowser = createAction('location/ERROR_BROWSER');
export const exploreSortingQuiz = createAction('location/EXPLORE_SORTING_QUIZ');
export const exploreSortingQuizResults = createAction(
  'location/EXPLORE_SORTING_QUIZ_RESULTS'
);
export const moduleLanding = createAction('location/MODULE_LANDING');
export const passwordReset = createAction('location/PASSWORD_RESET');
export const passwordResetRequest = createAction(
  'location/PASSWORD_RESET_REQUEST'
);
export const passwordResetSent = createAction('location/PASSWORD_RESET_SENT');
export const path = createAction('location/PATH');
export const enrolledPathSyllabus = createAction(
  'location/ENROLLED_PATH_SYLLABUS'
);
export const externalOrgPath = createAction('location/EXTERNAL_ORG_PATH');
export const pathsNew = createAction('location/PATHS_NEW');
export const skillPaths = createAction('location/SKILL_PATHS');
export const subscriptionsCancellationConfirm = createAction(
  'location/SUBSCRIPTIONS_CANCELLATION_CONFIRM'
);
export const subscriptionsCancellation = createAction(
  'location/SUBSCRIPTIONS_CANCELLATION'
);
export const subscriptionsCancellationSurvey = createAction(
  'location/SUBSCRIPTIONS_CANCELLATION_SURVEY'
);
export const subscriptionsTrialExtensionIncentive = createAction(
  'location/SUBSCRIPTIONS_TRIAL_EXTEND_INCENTIVE'
);
export const subscriptionsCancellationIncentivePromo = createAction(
  'location/SUBSCRIPTIONS_CANCELLATION_INCENTIVE_PROMO'
);
export const subscriptionsCheckout = createAction(
  'location/SUBSCRIPTIONS_CHECKOUT'
);
export const subscriptionsPause = createAction('location/SUBSCRIPTIONS_PAUSE');
export const subscriptionsUpgrade = createAction(
  'location/SUBSCRIPTIONS_UPGRADE'
);
export const subscriptionsPaid = createAction('location/SUBSCRIPTIONS_PAID');

export const businessCheckout = createAction('location/BUSINESS_CHECKOUT');

export const businessCheckoutConfirmation = createAction(
  'location/BUSINESS_CHECKOUT_CONFIRMATION'
);

export const talentOnboarding = createAction('location/TALENT_ONBOARDING');

export const businessTrialOptIn = createAction(
  'location/BUSINESS_TRIAL_OPT_IN'
);

export const businessBillingAdmin = createAction(
  'location/BUSINESS_BILLING_ADMIN'
);

export const trialExtensionSurvey = createAction(
  'location/TRIAL_EXTENSION_SURVEY'
);
