export const queryReleasedContent = `
query releasedContent {
  releasedContent {
    path_ids: pathIds
    path_slugs: pathSlugs
    track_ids: trackIds
    track_slugs: trackSlugs
    beta_track_ids: betaTrackIds
    beta_track_slugs: betaTrackSlugs
    pro_exclusive_track_ids: proExclusiveTrackIds
    pro_exclusive_track_slugs: proExclusiveTrackSlugs
    certificate_denylist_track_ids: certificateDenylistTrackIds
  }
}
`;
