import { intersection, isEmpty } from 'lodash';
import { createAction } from 'redux-actions';

import { graphqlGatewayRequest } from '~/libs/graphqlGatewayRequest';
import { selectGraphQLGatewayBaseUrl } from '~/state/apiConfig/selectors';
import { selectUserJWT } from '~/state/currentUser/selectors';
import { pathProgressRequested } from '~/state/entities/containerProgresses/actions';
import { readContentItemProgressesRequested } from '~/state/entities/contentItemProgresses/actions';
import { readContentItemsRequested } from '~/state/entities/contentItems/actions';
import { fetchModuleData } from '~/state/entities/modules/actions';
import { selectModules } from '~/state/entities/modules/selectors';
import { selectPath, selectPathBySlug } from '~/state/entities/paths/selectors';
import { fetchTrackDataById } from '~/state/entities/tracks/actions';
import { selectTracks } from '~/state/entities/tracks/selectors';
import { fetchReleasedContent } from '~/state/releasedContent/actions';
import { selectReleasedPathIds } from '~/state/releasedContent/selectors';
import { Dispatch, SiteState, SiteThunkAction } from '~/state/types';
import { Path } from '~/typings/entities/path';

import { massageV4PathResponse } from '../helpers';
import { queryPath, queryPaths } from './queries';

export const readPathsSucceeded = createAction('entities/READ_PATHS_SUCCEEDED');

export const readPathsFailed = createAction('entities/READ_PATHS_FAILED');

export interface PathsSearchRequestData {
  id?: string | string[];
  slug?: string | string[];
  minimal?: boolean;
  goal?: 'career' | 'skill';
  limit?: number;
}

const searchReleasedPaths = (requestData: PathsSearchRequestData) => async (
  dispatch: Dispatch,
  getState: () => SiteState
) => {
  await dispatch(fetchReleasedContent());
  const store = getState();
  const releasedPathIds = await selectReleasedPathIds(store);
  const jwt = selectUserJWT(store);

  if (isEmpty(requestData.id)) {
    requestData.id = releasedPathIds;
  } else {
    requestData.id = intersection(requestData.id, releasedPathIds);
  }

  const url = selectGraphQLGatewayBaseUrl(getState());
  return graphqlGatewayRequest({
    url,
    jwt,
    query: queryPaths,
    variables: {
      id: requestData.id,
      slug: requestData.slug,
      goal: requestData.goal,
    },
  });
};

export const fetchAllCareerPaths = () => async (
  dispatch: Dispatch,
  getState: () => SiteState
) => {
  const jwt = selectUserJWT(getState());
  const url = selectGraphQLGatewayBaseUrl(getState());
  const { paths } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryPaths,
    variables: {
      id: null,
      slug: null,
      goal: 'career',
      includeUnreleased: true,
    },
  })) as PathsResponseV4;

  dispatch(readPathsSucceeded(paths));
};

export const fetchReleasedCareerPaths = (): SiteThunkAction => async (
  dispatch,
  getState
) => {
  const { paths } = (await searchReleasedPaths({
    minimal: false,
    goal: 'career',
  })(dispatch, getState)) as PathsResponseV4;

  dispatch(readPathsSucceeded(paths));
};

export const fetchReleasedSkillPaths = (): SiteThunkAction => async (
  dispatch,
  getState
) => {
  const { paths: data } = (await searchReleasedPaths({
    minimal: false,
    goal: 'skill',
  })(dispatch, getState)) as PathsResponseV4;

  dispatch(readPathsSucceeded(data));
};

export interface PathsResponseV4 {
  paths: Path[];
}

export const fetchPathData = (
  ids: string[]
): SiteThunkAction<Path[] | null> => async (dispatch, getState) => {
  const idsToFetch = ids.filter((id) => !selectPath(getState(), id));
  const jwt = selectUserJWT(getState());

  if (!idsToFetch.length) return null;

  const url = selectGraphQLGatewayBaseUrl(getState());
  let { paths } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryPaths,
    variables: {
      id: idsToFetch,
      slug: null,
      goal: null,
      includeUnreleased: true,
    },
  })) as PathsResponseV4;

  paths = paths.map(massageV4PathResponse);

  dispatch(readPathsSucceeded(paths));
  return paths;
};

export const fetchPathDataBySlug = (slug: string): SiteThunkAction => async (
  dispatch,
  getState
) => {
  const store = getState();
  const slugsToFetch = [slug].filter((id) => !selectPathBySlug(store, id));
  const jwt = selectUserJWT(store);
  if (!slugsToFetch.length) return;

  const url = selectGraphQLGatewayBaseUrl(getState());
  let { paths = [] } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryPaths,
    variables: {
      slug: slugsToFetch,
      id: null,
      goal: null,
      includeUnreleased: true,
    },
  })) as PathsResponseV4;

  if (!paths.length) {
    dispatch(readPathsFailed());
    return;
  }

  paths = paths.map(massageV4PathResponse);

  dispatch(readPathsSucceeded(paths));
};

export interface PathPreviewRequest {
  id?: string;
  slug?: string;
}

export interface PathV4Response {
  path: Path;
}

// TODO: migrate to csv4 once previews are supported
export const fetchPathPreviewData = ({
  id,
  slug,
}: PathPreviewRequest): SiteThunkAction => async (dispatch, getState) => {
  const jwt = selectUserJWT(getState());

  const url = selectGraphQLGatewayBaseUrl(getState());
  let { path } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryPath,
    variables: {
      id,
      slug,
      draft: true,
    },
    bypassCache: true,
  })) as PathV4Response;
  path = massageV4PathResponse(path);
  path.id = path.sourceId;

  dispatch(readPathsSucceeded([path]));
};

export const fetchContentItemsAndContentItemProgressesForTracks = (
  trackIds: string[]
): SiteThunkAction => async (dispatch, getState) => {
  await dispatch(fetchTrackDataById(trackIds));
  const tracks = selectTracks(getState(), trackIds);
  if (!tracks) return;
  const moduleIds = tracks
    .map((t) => t.module_ids)
    .reduce((acc, moduleIdArray) => acc.concat(moduleIdArray), []);

  await dispatch(
    fetchContentItemsAndContentItemProgressesForModules(moduleIds)
  );
};

export const fetchContentItemsAndContentItemProgressesForModules = (
  moduleIds: string[]
): SiteThunkAction => async (dispatch, getState) => {
  await dispatch(fetchModuleData(moduleIds));

  const modules = selectModules(getState(), moduleIds);

  const contentItemIds = modules
    .map((m) => m.content_item_ids)
    .reduce((acc, curr) => acc.concat(curr), []);

  await Promise.all([
    dispatch(readContentItemsRequested({ ids: contentItemIds })),
    dispatch(
      readContentItemProgressesRequested(contentItemIds, { bustCache: true })
    ),
  ]);
};

export const fetchPathTrackModuleAndContentItemData = ({
  id,
  slug,
  draft,
}: {
  id: string;
  slug?: string;
  draft?: boolean;
}): SiteThunkAction =>
  async function fetchPathTrackModuleAndContentItemDataThunk(
    dispatch,
    getState
  ) {
    let trackIds: string[] | undefined;
    dispatch(pathProgressRequested(id));

    if (draft) {
      await dispatch(fetchPathPreviewData({ id }));
      trackIds = selectPath(getState(), id).track_ids;
    } else if (slug) {
      await dispatch(fetchPathDataBySlug(slug));
      trackIds = selectPathBySlug(getState(), slug)!.track_ids;
    } else {
      await dispatch(fetchPathData([id]));
      trackIds = selectPath(getState(), id).track_ids;
    }

    if (!trackIds) return;

    await dispatch(
      fetchContentItemsAndContentItemProgressesForTracks(trackIds)
    );
  };
