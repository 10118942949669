import React from 'react';

export interface SolverSvgProps {
  className?: string;
}

export const SolverSvg = ({ className }: SolverSvgProps) => (
  <svg className={className} viewBox="0 0 48 48" aria-hidden="true">
    <g strokeWidth="1" fillRule="evenodd">
      <circle strokeWidth="2" fillRule="nonzero" cx="24" cy="24" r="23" />
    </g>
  </svg>
);
