export const queryPaths = `query pathsById($id: [ID!], $slug: [String!], $goal: [String!], $includeUnreleased: Boolean) {
  paths(id: $id, slug: $slug, goal: $goal, includeUnreleased: $includeUnreleased) {
    id
    title
    slug
    container_type: __typename
    categories {
      slug
      title
      type: classification
    }
    completion_time: completionTime
    goal
    published
	  released
    track_ids: trackIds
    created_at: createdAt
    updated_at: updatedAt
    last_modified_at: updatedAt
    description
    short_description: shortDescription
    alert_description: alertDescription
    alert_type: alertType
    alert_cta: alertCta
    alert_cta_destination: alertCtaDestination
    completion_message: completionMessage
    theme_color: themeColor
    video_url: videoUrl
    difficulty
    category_ids: categoryIds
    lesson_count: contentItemCount(contentItemType: Lesson)
    project_count: contentItemCount(contentItemType: Project)
    article_count: contentItemCount(contentItemType: Article)
    quiz_count: contentItemCount(contentItemType: Quiz)
    organization_id: organizationId
  }
}`;

export const queryPath = `query pathsById($id: ID, $slug: String, $draft: Boolean) {
  path(id: $id, slug: $slug, draft: $draft) {
    id
    title
    slug
    container_type: __typename
    categories {
      slug
      title
      type: classification
    }
    completion_time: completionTime
    goal
    published
	  released
    track_ids: trackIds
    created_at: createdAt
    updated_at: updatedAt
    last_modified_at: updatedAt
    description
    short_description: shortDescription
    alert_description: alertDescription
    alert_type: alertType
    alert_cta: alertCta
    alert_cta_destination: alertCtaDestination
    completion_message: completionMessage
    theme_color: themeColor
    video_url: videoUrl
    difficulty
    category_ids: categoryIds
    lesson_count: contentItemCount(contentItemType: Lesson)
    project_count: contentItemCount(contentItemType: Project)
    article_count: contentItemCount(contentItemType: Article)
    quiz_count: contentItemCount(contentItemType: Quiz)
    organization_id: organizationId
    sourceId
  }
}`;
