import { handleActions } from 'redux-actions';

import { addEntities } from '~/libs/state/entityHelpers';
import { ById, Journey } from '~/typings/entities';

import { readEntitiesSucceeded } from '../actions';

// if areLoaded is undefined, then a request for journeys is still processing
export type JourneyState = ById<Journey> & {
  areLoaded?: boolean;
};

const defaultState: JourneyState = {
  byId: {},
};

export const journeys = handleActions<JourneyState, {}>(
  {
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.journeys) return state;
      const newState = { ...state, areLoaded: true };

      return addEntities(newState, payload.journeys.byId) as ById<Journey>;
    },
  },
  defaultState
);
