import { handleActions } from 'redux-actions';

import { addEntities } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';

const defaultState = {
  byId: {},
};

export const contentItemProgresses = handleActions(
  {
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.contentItemProgresses) return state;
      if (payload.contentItemProgresses.byId) {
        return addEntities(state, payload.contentItemProgresses.byId, {
          idKey: 'id',
        });
      }
      return addEntities(state, payload.contentItemProgresses, { idKey: 'id' });
    },
  },
  defaultState
);
