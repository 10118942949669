import React from 'react';

export interface ConnectorSvgProps {
  className?: string;
}

export const ConnectorSvg = ({ className }: ConnectorSvgProps) => (
  <svg className={className} viewBox="0 0 48 48" aria-hidden="true">
    <g strokeWidth="1" fillRule="evenodd">
      <rect
        strokeWidth="2"
        fillRule="nonzero"
        x="1"
        y="1"
        width="46"
        height="46"
      />
    </g>
  </svg>
);
