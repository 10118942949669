import { combineReducers } from 'redux';

import { accountSettings } from './accountSettings/reducers';
import { dashboard } from './dashboard/reducers';
import { enterprise } from './enterprise/reducers';
import { paths } from './paths/reducers';
import { sortingQuiz } from './sortingQuiz/reducers';

const staticReducer = (state = {}) => state;

export const scenes = combineReducers({
  accountSettings,
  adminTestPlan: staticReducer,
  articles: staticReducer,
  businessBillingAdmin: staticReducer,
  businessLanding: staticReducer,
  businessTrial: staticReducer,
  cookiePolicy: staticReducer,
  studentVerification: staticReducer,
  confirmEmail: staticReducer,
  communityLanding: staticReducer,
  dashboard,
  enterprise,
  paths,
  sortingQuiz,
});
