import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const releasedContent = handleActions(
  {
    [`${actions.releasedContentLoaded}`]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  {}
);
