import { handleActions } from 'redux-actions';

import * as actions from './actions';
import questions from './questions.json';
import { SortingQuizState } from './types';

const defaultState = {
  questions,
  answers: questions.map(() => null),
  recommendations: {},
};

export const sortingQuiz = handleActions<SortingQuizState, {}>(
  {
    [`${actions.nextQuestion}`]: (state) => {
      return {
        ...state,
      };
    },
    [`${actions.recordAnswer}`]: (
      state,
      {
        payload: { questionIndex, answerIndex },
      }: Required<ReturnType<typeof actions.recordAnswer>>
    ) => {
      const updatedAnswers = [...state.answers];
      updatedAnswers[questionIndex] = answerIndex;
      return {
        ...state,
        answers: updatedAnswers,
      };
    },
  },
  defaultState
);
