import { createAction } from 'redux-actions';

import {
  exploreSortingQuiz,
  exploreSortingQuizResults,
} from '~/portal/state/location/actions';
import { PortalThunkAction } from '~/portal/state/types';

import { selectResultsFromQuizAnswers } from './selectors';
import { RecordAnswerPayload } from './types';

export const recordAnswer = createAction<RecordAnswerPayload>(
  'SORTING_QUIZ/RECORD_ANSWER'
);

export const nextQuestion = (): PortalThunkAction => {
  return async (dispatch, getState) => {
    const state = getState();
    const { questions, answers } = state.scenes.sortingQuiz;
    const { currentQuestionIndex } = state.location.query;

    const incrementedQuestionIndex = Number(currentQuestionIndex) + 1;
    if (incrementedQuestionIndex >= questions.length) {
      const resultsList = selectResultsFromQuizAnswers(questions, answers);
      const resultsQuery = resultsList.reduce<Record<string, number>>(
        (query, curr) => {
          const [archetype, score] = curr;
          query[archetype] = score;
          return query;
        },
        {}
      );
      dispatch(exploreSortingQuizResults({ query: resultsQuery }));
      return;
    }
    dispatch(
      exploreSortingQuiz({
        query: { currentQuestionIndex: incrementedQuestionIndex },
      })
    );
  };
};
