export const queryContentItemsByIdsOrSlugs = `query contentItemsByIdsOrSlugs($ids: [ID!], $slugs: [String!], $minimal: Boolean!) {
  contentItems(id: $ids, slug: $slugs) {
    ...on ContentItemInterface {
      id
      description
      is_deprecated: isDeprecated
      is_free: isFree
      learningStandardIds
      published
      slug
      title
      time
      type
    }
    
    ...on Article {
      body @skip(if: $minimal)
    }

    ...on Informational {
      body @skip(if: $minimal)
    }
    
    ...on Lesson {
      published_exercise_ids: publishedExerciseIds
      exercise_ids: exerciseIds
    }

    ...on KanbanProject {
      kanban_project_information: kanbanProjectInformation @skip(if: $minimal) {
        long_overview: longOverview
        resources
        setup_instructions: setupInstructions
        short_overview: shortOverview
        kanban_tasks: tasks {
          description
          hint
          id
          title
        }
      }
    }

    ...on ExternalResource {
      body @skip(if: $minimal)
      resource_type: resourceType
      source_name: sourceName
      resource_url: resourceUrl
      image {
        alt
        url
      }
    }
    
    ...on Project {
      standalone
      thumbnail_url: thumbnailUrl
      project_information: projectInformation @skip(if: $minimal) {
        ein_config: einConfig
        download_path: downloadPath
        objective
        preview_url: previewUrl
        thumbnail_url: thumbnailUrl
        video_url: videoUrl
        
        workspace {
          id
          slug
          type: backendType
        }
        
        self_assessments: taskGroups {
          heading
          tasks {
            hint
            task_key: taskKey
            text
          }
        }
        
        components: layoutComponents {
          ...sharedLayoutComponentFields

          ... on WebBrowser {
            display_url: displayUrl
          }

          ... on JupyterBrowser {
            display_url: displayUrl
          }

          ...on Narrative {
            content
            instructions
          }

          ...on LayoutImage {
            image_url: imageUrl
            alt_text: altText
          }

          ...on PersistentCodeEditor {
            files
            navigator_disabled: navigatorDisabled
            no_run_button:noRunButton
          }

          ...on ReadOnlyCodeEditor {
            file_path: filePath
          }

          ...on ExpoCodeEditor {
            files
          }

          ...on Terminal {
            shell
            prevent_checkpoint_run: preventCheckpointRun
            fake_responses: fakeResponses {
              id
              delay
              match_pattern: matchPattern
              output_content: outputContent
              output_type: outputType
            }
          }

          ...on Video {
            video_urls: videoUrls
            autoplay
            mute
            loop
            show_controls: showControls
          }
        }
      }
    }
    
    ...on Quiz {
      assessment_ids: assessmentIds
      assessments @skip(if: $minimal) {
        ...on AssessmentInterface {
          id
          type
          prompt
        }

        ... on ExecutableCode {
          components: layoutComponents {
            ... on LayoutComponentInterface {
              id
              type
            }
            ... on ExpoCodeEditor {
              files
            }
            ... on PersistentCodeEditor {
              files
            }
            ... on WebBrowser {
              display_url: displayUrl
            }
          }
          test {
            id
             test_type: testType
             test_code: testCode
             test_options: testOptions
          }
          ein_config: einConfig
        }
        
        ...on FillInCode {
          snippet_template: snippetTemplate
          code_language: codeLanguage
          decoy_answers: decoyAnswers
          answers {
            id
            hint
            text
          }
        }

        ... on MultipleChoice {
          correct_answer: correctAnswer
          answers {
            text
            reason
          }
          image {
            url
            alt
          }
          latex_block: latexBlock {
            text
          }
          code_snippet: codeSnippet {
            language
            text
          }
        }
      }
    }
    
    ...on VideoContentItem {
      video_url: videoUrl
      body
    }
  }
}

fragment sharedLayoutComponentFields on LayoutComponentInterface {
  id
  type
}`;
