import { combineReducers } from 'redux';

import { categories } from '~/state/entities/categories/reducers';
import { containerProgresses } from '~/state/entities/containerProgresses/reducers';
import { contentItems } from '~/state/entities/contentItems/reducers';
import { exercises } from '~/state/entities/exercises/reducers';
import { journeys } from '~/state/entities/journeys/reducers';
import { modules } from '~/state/entities/modules/reducers';
import { paths } from '~/state/entities/paths/reducers';
import { tracks } from '~/state/entities/tracks/reducers';

import { contentItemProgresses } from './contentItemProgresses/reducers';
import { courseProgresses } from './courseProgresses/reducers';
import { courses } from './courses/reducers';
import { reviewCards } from './reviewCards/reducers';
import { syllabuses } from './syllabuses/reducers';

const staticObjReducer = (state = {}) => state;

export const entities = combineReducers({
  categories,
  containerProgresses,
  contentItemProgresses,
  contentItems,
  courseProgresses,
  courses,
  exercises,
  journeys,
  modules,
  paths,
  relatedCourses: staticObjReducer,
  relatedPaths: staticObjReducer,
  reviewCards,
  syllabuses,
  tracks,
});
