import { handleActions } from 'redux-actions';

import { readScenesSucceeded } from '../actions';
import * as actions from './actions';
import { AccountSettingsState } from './types';

const defaultState = {
  onboardingQuestionnaire: {},
  loading: false,
};

export const accountSettings = handleActions<
  AccountSettingsState,
  {} | undefined
>(
  {
    [`${actions.sceneDataRequested}`]: (state) => ({
      ...state,
      loading: true,
    }),
    [`${actions.sceneDataFailed}`]: (
      state,
      {
        payload: { error },
      }: Required<ReturnType<typeof actions.sceneDataFailed>>
    ) => ({
      ...state,
      loading: false,
      error,
    }),
    [`${readScenesSucceeded}`]: (
      state,
      { payload }: Required<ReturnType<typeof readScenesSucceeded>>
    ) => {
      if (!payload?.accountSettings) return state;
      return {
        ...state,
        ...payload.accountSettings,
        loading: false,
      };
    },
  },
  defaultState
);
