import { createAction } from 'redux-actions';

import { graphqlGatewayRequest } from '~/libs/graphqlGatewayRequest';
import { selectGraphQLGatewayBaseUrl } from '~/state/apiConfig/selectors';
import { selectUserJWT } from '~/state/currentUser/selectors';
import { selectModule } from '~/state/entities/modules/selectors';
import { Module, ReviewCard } from '~/typings/entities';

import { SiteThunkAction } from '../../types';
import { queryContentModulesById } from './queries';

export interface ModulesResponseV4 {
  contentModules: Module[];
}

export const readModulesSucceeded = createAction(
  'entities/READ_MODULES_SUCCEEDED'
);

export const modulesReceived = createAction<Module[]>(
  'entities/READ_MODULES_SUCCEEDED'
);

export const fetchModuleData = (
  ids: string[]
): SiteThunkAction<{} | undefined> => async (dispatch, getState) => {
  const store = getState();
  const idsToFetch = ids.filter((id) => !selectModule(store, id));
  const jwt = selectUserJWT(store);

  if (!idsToFetch.length) return {};

  const url = selectGraphQLGatewayBaseUrl(getState());
  const { contentModules } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryContentModulesById,
    variables: {
      id: idsToFetch,
    },
  })) as ModulesResponseV4;
  dispatch(readModulesSucceeded(contentModules));
};

export const moduleReviewCardsReceived = createAction<{
  moduleId: string;
  reviewCards: ReviewCard[];
}>('entities/READ_MODULES_REVIEW_CARDS_SUCCEEDED');
