import { createSelector } from 'reselect';

import { PortalState } from '~/portal/state/types';

export const selectOnboardingQuestionnaire = (state: PortalState) =>
  state.scenes.accountSettings.onboardingQuestionnaire;

export const selectPurposeChoices = createSelector(
  selectOnboardingQuestionnaire,
  (questionnaire) => {
    if (!questionnaire) return undefined;

    return questionnaire.purposeQuestion?.choices;
  }
);
