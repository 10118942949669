import { createSelector } from 'reselect';

import { SiteState } from '~/state/types';

export const selectAllContentItems = (state: SiteState) =>
  state.entities.contentItems.byId;

export const selectContentItemsByIds = createSelector(
  selectAllContentItems,
  (_state: SiteState, ids: string[]) => ids,
  (contentItems, ids) =>
    ids.map((contentItemId) => contentItems[contentItemId]).filter(Boolean)
);

export const selectContentItem = createSelector(
  selectAllContentItems,
  (_: SiteState, id: string | undefined) => id,
  (contentItems, id) =>
    Object.values(contentItems).find(
      (contentItem) => contentItem.id === id || contentItem.uuid === id
    )
);

export const selectContentItemBySlug = createSelector(
  selectAllContentItems,
  (_: SiteState, slug: string) => slug,
  (contentItems, slug) =>
    Object.values(contentItems).find((contentItem) => contentItem.slug === slug)
);

export const selectContentItemIsLesson = createSelector(
  selectContentItem,
  (contentItem) => contentItem?.type === 'lesson'
);
