import { isEmpty } from 'lodash';
import { createAction } from 'redux-actions';

import { graphqlGatewayRequest } from '~/libs/graphqlGatewayRequest';
import { selectGraphQLGatewayBaseUrl } from '~/state/apiConfig/selectors';
import { SiteThunkAction } from '~/state/types';

import { selectUserJWT } from '../currentUser/selectors';
import { queryReleasedContent } from './queries';
import { selectReleasedContent } from './selectors';
import { ReleasedContentState } from './types';

export const releasedContentLoaded = createAction('RELEASED_CONTENT_LOADED');

export interface ReleasedContentResponseV4 {
  releasedContent: ReleasedContentState;
}

export const fetchReleasedContent = (): SiteThunkAction => async (
  dispatch,
  getState
) => {
  const state = getState();
  if (!isEmpty(selectReleasedContent(state))) return;

  const jwt = selectUserJWT(state);

  const url = selectGraphQLGatewayBaseUrl(getState());
  const { releasedContent } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryReleasedContent,
  })) as ReleasedContentResponseV4;

  dispatch(releasedContentLoaded(releasedContent));
};
