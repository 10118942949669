import { handleActions } from 'redux-actions';

import { addEntities } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';

const defaultState = {
  byUuid: {},
};

export const syllabuses = handleActions(
  {
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.syllabuses) return state;
      return addEntities(state, payload.syllabuses.byUuid, { idKey: 'uuid' });
    },
  },
  defaultState
);
