import { genericRequest, handleResponse } from '~/libs/genericRequest';
import { logger } from '~/libs/logging/logger';

import {
  GraphqlGatewayRequestHeaders,
  GraphqlGatewayRequestProps,
  GraphqlGatewayResponse,
} from './types';

export const graphqlGatewayRequest = async ({
  url = 'https://graphql-gateway.staging-eks.codecademy.com/',
  jwt,
  logAndSwallowErrors = true,
  query,
  variables = {},
  bypassCache,
}: GraphqlGatewayRequestProps) => {
  const headers: GraphqlGatewayRequestHeaders = {
    Authorization: `Bearer ${jwt}`,
    'apollographql-client-name': 'codecademy',
    'apollographql-client-version': CCDATA.dd_version || 'unknown',
    ...(bypassCache && { 'bypass-content-cache': true }),
  };

  const result = await genericRequest({
    endpoint: url,
    data: { query, variables },
    headers,
    method: 'POST',
  });

  const responseHandler = handleResponse<GraphqlGatewayResponse>({
    logAndSwallowErrors,
    errorMessage: `Unable to fetch data from graphql gateway.`,
  });

  const { errors, data } = await responseHandler(result);

  if (data !== undefined) {
    if (errors) {
      logger.error(
        `GraphQL errors: ${errors?.map((e) => e.message).join('\n')}`
      );
    }
    return data;
  }

  if (!logAndSwallowErrors) {
    throw new Error(
      `GraphQL errors: ${errors?.map((e) => e.message).join('\n')}`
    );
  } else {
    logger.error(`GraphQL errors: ${errors?.map((e) => e.message).join('\n')}`);
  }
};
