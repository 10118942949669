import { createAction } from 'redux-actions';

import { PortalThunkAction } from '~/portal/state/types';
import {
  fetchPathData,
  fetchPathDataBySlug,
  fetchPathPreviewData,
  fetchPathTrackModuleAndContentItemData,
} from '~/state/entities/paths/actions';
import { selectPath, selectPathBySlug } from '~/state/entities/paths/selectors';
import { fetchTrackDataById } from '~/state/entities/tracks/actions';

import { resumeContentRequested } from '../dashboard/resume/actions';

export const pathSceneLoaded = createAction('scenes/paths/PATH_SCENE_LOADED');

export const fetchSyllabusData = ({
  id,
  slug,
  draft,
}: {
  id: string;
  slug?: string;
  draft?: boolean;
}): PortalThunkAction => async (dispatch) => {
  await dispatch(fetchPathTrackModuleAndContentItemData({ id, slug, draft }));
  await dispatch(resumeContentRequested());
  dispatch(pathSceneLoaded());
};

export interface SlugAndId {
  slug: string;
  id: string;
  draft: boolean;
}

export const fetchPathAndTrackData = ({
  slug,
  id,
  draft,
}: SlugAndId): PortalThunkAction => async (dispatch, getState) => {
  let trackIds: string[];

  if (draft && slug) {
    await dispatch(fetchPathPreviewData({ slug }));
    trackIds = selectPathBySlug(getState(), slug)!.track_ids;
  } else if (slug) {
    await dispatch(fetchPathDataBySlug(slug));
    trackIds = selectPathBySlug(getState(), slug)!.track_ids;
  } else {
    await dispatch(fetchPathData([id]));
    trackIds = selectPath(getState(), id).track_ids;
  }
  await dispatch(fetchTrackDataById(trackIds));
};
