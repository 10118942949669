import { handleActions } from 'redux-actions';

import { readScenesSucceeded } from '~/portal/state/scenes/actions';

import {
  getSecondaryResumeDataSucceeded,
  resumeContentReceived,
} from './resume/actions';
import { DashboardSceneState } from './types';

const defaultState: DashboardSceneState = {
  nextContentItemId: {},
};

export const dashboard = handleActions<DashboardSceneState, {}>(
  {
    [`${readScenesSucceeded}`]: (
      state,
      { payload }: Required<ReturnType<typeof readScenesSucceeded>>
    ) => {
      if (!payload.dashboard) return state;
      return {
        ...state,
        ...payload.dashboard,
      };
    },
    [`${resumeContentReceived}`]: (
      state,
      { payload }: Required<ReturnType<typeof resumeContentReceived>>
    ) => ({
      ...state,
      resume: {
        ...state.resume,
        ...payload,
      },
    }),
    [`${getSecondaryResumeDataSucceeded}`]: (
      state,
      { payload }: Required<ReturnType<typeof getSecondaryResumeDataSucceeded>>
    ) => {
      const containerId = payload?.containerId;
      const newResumeData = payload?.resume;
      return {
        ...state,
        secondaryResumeData: {
          ...state.secondaryResumeData,
          [containerId]: newResumeData,
        },
      };
    },
  },
  defaultState
);
