import { theme } from '@codecademy/gamut-styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { createRootComponent } from '~/components/createRootComponent';
import { ServerAlerts, ServerAlertsProvider } from '~/components/ServerAlerts';
import { trackUserVisit } from '~/libs/eventTracking';
import { getRouteMetaForLocation } from '~/libs/location/routing';
import { createReduxBinder } from '~/libs/reduxBinder';
import { routesMeta } from '~/portal/routes';
import { useSetDatadogContext } from '~/state/hooks/useSetDatadogContext';
import { selectLocationType } from '~/state/location/selectors';

import { PortalState } from './state/types';
import { configureStore } from './store/configureStore';

export interface PortalProps {
  portalData: unknown;
  reduxData: Partial<PortalState>;
  statusCode: number;
}

/**
 * Main portal wrapper
 */
export const PortalRouter: React.FC<React.PropsWithChildren<PortalProps>> = ({
  portalData = {},
  statusCode,
  ...otherProps
}) => {
  const locationType = useSelector(selectLocationType);
  const { scene: Scene, pageName } = getRouteMetaForLocation(
    routesMeta,
    locationType,
    statusCode
  );

  useEffect(() => {
    if (pageName) trackUserVisit({ page_name: pageName });
  }, [pageName]);

  useSetDatadogContext();

  return (
    <ServerAlertsProvider>
      <ServerAlerts />
      <Scene portalData={portalData} {...otherProps} />
    </ServerAlertsProvider>
  );
};

export default createReduxBinder(
  createRootComponent(PortalRouter, { theme }),
  configureStore
);
