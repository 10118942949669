import { planRoleStringsFreeAM } from '../BusinessAccountManagement/businessPlanStrings';

export const inviteStatusStrings = {
  pending: 'Pending',
  accepted: 'Accepted',
};

export const enterprisePlanStrings = {
  ...planRoleStringsFreeAM,
  ...inviteStatusStrings,
};

export type EnterprisePlanStrings = typeof enterprisePlanStrings;

export type EnterpriseRoleKeys = keyof EnterprisePlanStrings;

export const planRoleSuccessMessage = (
  email: string,
  role: EnterpriseRoleKeys
): string => {
  const newRole = enterprisePlanStrings[role];
  const { team, account_manager } = planRoleStringsFreeAM;
  switch (newRole) {
    case team:
      return `${email} is now a ${newRole}.`;
    case account_manager:
    default:
      return `${email} is now an ${newRole}.`;
  }
};
