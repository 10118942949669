import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { SiteState } from '~/state/types';
import { ContentItemProgress } from '~/typings/entities/progress';

export const selectContentItemProgressesUntransformed = (state: SiteState) =>
  state.entities.contentItemProgresses.byId;

const computeContentItemProgressPercentage = (
  progress = {} as Partial<ContentItemProgress>
) => {
  switch (progress.type) {
    case 'lesson':
      if (progress.exercises_total) {
        const decimalProgress =
          (progress.exercises_completed || 0) / progress.exercises_total;
        return Math.floor(decimalProgress * 100);
      }
      return 0;
    case 'quiz':
      return progress.highest_pct || 0;
    case 'project':
      return progress.progress_pct || 0;
    case 'video':
      return progress.completed ? 100 : 0;
    case 'article':
      return progress.completed ? 100 : 0;
    case 'informational':
      return progress.completed ? 100 : 0;
    default:
      return 0;
  }
};

export const selectContentItemProgressesObject = createSelector(
  selectContentItemProgressesUntransformed,
  (contentItemProgressesById: Record<string, ContentItemProgress>) => {
    const contentItemProgressesKeys = Object.keys(contentItemProgressesById);
    return contentItemProgressesKeys
      .map((id) => {
        const progress = contentItemProgressesById[id];
        return {
          ...progress,
          progress_percentage: computeContentItemProgressPercentage(progress),
        };
      })
      .reduce(
        (acc, item, index) => ({
          ...acc,
          [contentItemProgressesKeys[index]]: item,
        }),
        {} as Record<string, ContentItemProgress>
      );
  }
);

export const selectContentItemProgress = createSelector(
  selectContentItemProgressesObject,
  (state: SiteState, id: string) => id,
  (contentItemProgresses: Record<string, ContentItemProgress>, id: string) =>
    contentItemProgresses[id]
);

export const getContentItemsProgressSummary = (
  contentItemProgresses: ContentItemProgress[]
) => {
  if (!contentItemProgresses?.length) return 0;

  const progressDecimal =
    contentItemProgresses.reduce(
      (acc, curr) => acc + (curr?.completed ? 1 : 0),
      0
    ) / contentItemProgresses.length;

  return Math.floor(progressDecimal * 100);
};

export const selectFirstIncompleteItemIdForIds = createSelector(
  selectContentItemProgressesUntransformed,
  (state: SiteState, contentItemIds: string[] | undefined) => contentItemIds,
  (contentItemProgresses, contentItemIds = []) => {
    if (isEmpty(contentItemProgresses) || isEmpty(contentItemIds))
      return undefined;

    return contentItemIds.filter(
      (id) => !contentItemProgresses[id]?.completed
    )?.[0];
  }
);
