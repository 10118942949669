import { createAction } from 'redux-actions';
import uuid from 'uuid/v1';

import { ShowModalPayload } from './types';

export const showModal = createAction<
  ShowModalPayload,
  Pick<ShowModalPayload, 'type'> & Record<string, any>
>('MODALS/SHOW_MODAL', (payload) => ({
  ...payload,
  id: uuid(),
}));

export const hideCurrentModal = createAction('MODALS/HIDE_CURRENT_MODAL');
export const hideModal = createAction<string>('MODALS/HIDE_MODAL');
