import loadable from '@loadable/component';

import { RouteMeta } from '~/libs/location/routing';
import * as sharedRouteActions from '~/libs/location/sharedRouteActions';
import * as routeActions from '~/portal/state/location/actions';

const Achievements = loadable(() => import('~/portal/scenes/Achievements'));
const Account = loadable(() => import('~/portal/scenes/Account'));
const AuthorizeOauth = loadable(() => import('~/portal/scenes/AuthorizeOauth'));

const BusinessCheckout = loadable(
  () => import('~/portal/scenes/BusinessCheckout')
);

const BusinessCheckoutConfirmation = loadable(
  () => import('~/portal/scenes/BusinessCheckout/ConfirmationLayout')
);
const BusinessBillingAdmin = loadable(
  () => import('~/portal/scenes/BusinessBillingAdmin')
);
const BusinessTeamsQuote = loadable(
  () => import('~/portal/scenes/BusinessTeamsQuote')
);
const CancellationConfirm = loadable(
  () => import('~/portal/scenes/CancellationConfirm')
);
const CancellationStart = loadable(
  () => import('~/portal/scenes/CancellationStart')
);
const CancellationSurvey = loadable(
  () => import('~/portal/scenes/CancellationSurvey')
);
const CancellationTrialExtensionIncentive = loadable(
  () => import('~/portal/scenes/CancellationTrialExtensionIncentive')
);
const CancellationIncentivePromo = loadable(
  () => import('~/portal/scenes/CancellationIncentivePromo')
);

const ChoosePathPage = loadable(
  () => import('~/portal/scenes/Paths/ChoosePathPage')
);
const Course = loadable(() => import('~/portal/scenes/CoursePages'));
const EnrolledCourseSyllabus = loadable(
  () => import('~/portal/scenes/EnrolledCourseSyllabus')
);
const ConfirmEmail = loadable(() => import('~/portal/scenes/ConfirmEmail'));
const EnterpriseRegistration = loadable(
  () => import('~/portal/scenes/Enterprise/Registration')
);
const EnterprisePlan = loadable(
  () => import('~/portal/scenes/BusinessAccountManagement/Plan')
);
const EnterprisePlans = loadable(
  () => import('~/portal/scenes/BusinessAccountManagement/Plans')
);
const Error404 = loadable(() => import('~/portal/scenes/Error404'));
const Error500 = loadable(() => import('~/portal/scenes/Error500'));
const ErrorBrowser = loadable(() => import('~/portal/scenes/ErrorBrowser'));
const CookiePolicy = loadable(() => import('~/portal/scenes/CookiePolicy'));
const DashboardResume = loadable(
  () => import('~/portal/scenes/DashboardResume')
);
const ExploreSortingQuiz = loadable(
  () => import('~/portal/scenes/explore/SortingQuiz')
);
const ExploreSortingQuizResults = loadable(
  () => import('~/portal/scenes/explore/SortingQuiz/Results')
);
const ModuleLanding = loadable(
  () => import('~/portal/scenes/CoursePages/ModuleLandingPage')
);
const Path = loadable(() => import('~/portal/scenes/Paths'));
const EnrolledPathSyllabus = loadable(
  () => import('~/portal/scenes/EnrolledPathSyllabus')
);
const ExternalOrgPathLandingPage = loadable(
  () => import('~/portal/scenes/ExternalOrgPathLandingPage')
);
const PauseSubscription = loadable(
  () => import('~/portal/scenes/PauseSubscription')
);
const PasswordReset = loadable(
  () => import('~/portal/scenes/Passwords/PasswordReset')
);
const PasswordResetRequest = loadable(
  () => import('~/portal/scenes/Passwords/PasswordResetRequest')
);
const PasswordResetSent = loadable(
  () => import('~/portal/scenes/Passwords/PasswordResetSent')
);
const PaymentCheckout = loadable(
  () => import('~/portal/scenes/PaymentCheckout')
);
const SubscriptionUpgrade = loadable(
  () => import('~/portal/scenes/SubscriptionUpgrade')
);
const SkillPaths = loadable(
  () => import('~/portal/scenes/Paths/ChooseSkillPathPage')
);
const TalentOnboarding = loadable(
  () => import('~/portal/scenes/Enterprise/Talent')
);

const TrialExtensionSurvey = loadable(
  () => import('~/portal/scenes/TrialExtensionSurvey')
);

const BusinessTrialOptIn = loadable(
  () => import('~/portal/scenes/BusinessTrial')
);

/**
 * Routes
 *
 * served by Redux First Router
 * used server and client side to render the current route's scene/component
 *
 * imports and routesMeta are organised alphabetically,
 * routesMap is organised alphabetically by _value_
 *
 * @type {ReactElement}
 */
export const routesMap = {
  [`${routeActions.account}`]: '/account/:section?',
  [`${routeActions.authorizeOauth}`]: '/oauth/authorize',
  [`${routeActions.confirmEmail}`]: '/confirm',
  [`${routeActions.cookiePolicy}`]: '/cookie-policy',
  [`${routeActions.businessTeamsQuote}`]: '/business/teams-quote',
  [`${routeActions.dashboardResume}`]: '/learn/resume',
  [`${routeActions.enterpriseRegistration}`]: '/business/register',
  [`${routeActions.enterprisePlans}`]: '/business/plans',
  [`${routeActions.enterprisePlan}`]: '/business/plans/:id',
  [`${routeActions.businessBillingAdmin}`]: '/business/plans/:id/billing_admin',
  [`${sharedRouteActions.error404}`]: '/errors/404',
  [`${sharedRouteActions.error500}`]: '/errors/500',
  [`${routeActions.errorBrowser}`]: '/errors/browser',
  [`${routeActions.exploreSortingQuiz}`]: '/explore/sorting-quiz',
  [`${routeActions.exploreSortingQuizResults}`]: '/explore/sorting-quiz/results',
  [`${routeActions.moduleLanding}`]: '/learn/:course_slug/modules/:module_slug',
  [`${routeActions.course}`]: '/learn/:course_slug',
  [`${routeActions.enrolledCourseSyllabus}`]: '/enrolled/courses/:course_slug',
  [`${routeActions.pathsNew}`]: '/learn/paths/new',
  [`${routeActions.path}`]: '/learn/paths/:page',
  [`${routeActions.enrolledPathSyllabus}`]: '/enrolled/paths/:page',
  [`${routeActions.externalOrgPath}`]: '/business/paths/:page',
  [`${routeActions.skillPaths}`]: '/learn/skill-paths/new',
  [`${routeActions.passwordReset}`]: '/secret/edit',
  [`${routeActions.passwordResetRequest}`]: '/secret/new',
  [`${routeActions.passwordResetSent}`]: '/secret/sent',
  [`${routeActions.subscriptionsCancellation}`]: '/subscriptions/:subscriptionId/cancellation',
  [`${routeActions.subscriptionsCancellationConfirm}`]: '/subscriptions/:subscriptionId/cancellation/confirm',
  [`${routeActions.subscriptionsCancellationSurvey}`]: '/subscriptions/:subscriptionId/cancellation/survey',
  [`${routeActions.subscriptionsTrialExtensionIncentive}`]: '/subscriptions/:subscriptionId/incentive',
  [`${routeActions.subscriptionsCancellationIncentivePromo}`]: '/subscriptions/:subscriptionId/incentive/:promo',
  [`${routeActions.subscriptionsCheckout}`]: '/subscriptions/:selected_plan_id/checkout',
  [`${routeActions.subscriptionsUpgrade}`]: '/subscriptions/upgrade',
  [`${routeActions.subscriptionsPaid}`]: '/subscriptions/:selected_plan_id/paid',
  [`${routeActions.subscriptionsPause}`]: '/subscriptions/:subscriptionId/pause',
  [`${routeActions.achievements}`]: '/users/:id/achievements',
  [`${routeActions.businessCheckout}`]: '/business/plans/:id/checkout/:transactionType/purchase',
  [`${routeActions.businessCheckoutConfirmation}`]: '/business/plans/:id/checkout/confirmation',
  [`${routeActions.talentOnboarding}`]: '/business/talent/onboarding',
  [`${routeActions.businessTrialOptIn}`]: '/business/trial/sign_up',
  [`${routeActions.trialExtensionSurvey}`]: '/subscriptions/:id/trialExtensionConfirmation',
};

export const routesMeta: Record<string, RouteMeta> = {
  [`${routeActions.achievements}`]: {
    scene: Achievements,
    pageName: 'achievements',
  },
  [`${routeActions.account}`]: {
    scene: Account,
    pageName: undefined,
  },
  [`${routeActions.authorizeOauth}`]: {
    scene: AuthorizeOauth,
    pageName: 'oauth_authorize',
  },
  [`${routeActions.businessCheckout}`]: {
    scene: BusinessCheckout,
    pageName: 'business_checkout',
  },
  [`${routeActions.businessCheckoutConfirmation}`]: {
    scene: BusinessCheckoutConfirmation,
    pageName: 'business_checkout_confirmation',
  },
  [`${routeActions.businessTeamsQuote}`]: {
    scene: BusinessTeamsQuote,
    pageName: 'business_teams_quote',
  },
  [`${routeActions.talentOnboarding}`]: {
    scene: TalentOnboarding,
    pageName: undefined,
  },
  [`${routeActions.businessTrialOptIn}`]: {
    scene: BusinessTrialOptIn,
    pageName: undefined,
  },
  [`${routeActions.confirmEmail}`]: {
    scene: ConfirmEmail,
    pageName: 'confirm_email',
  },
  [`${routeActions.course}`]: {
    scene: Course,
    pageName: undefined,
  },
  [`${routeActions.enrolledCourseSyllabus}`]: {
    scene: EnrolledCourseSyllabus,
    pageName: undefined,
  },
  [`${routeActions.cookiePolicy}`]: {
    scene: CookiePolicy,
    pageName: 'cookie_policy',
  },
  [`${routeActions.dashboardResume}`]: {
    scene: DashboardResume,
    pageName: undefined,
  },
  [`${routeActions.enterpriseRegistration}`]: {
    scene: EnterpriseRegistration,
    pageName: 'enterprise_registration',
  },
  [`${routeActions.enterprisePlan}`]: {
    scene: EnterprisePlan,
    pageName: 'enterprise_plan',
  },
  [`${routeActions.enterprisePlans}`]: {
    scene: EnterprisePlans,
    pageName: 'enterprise_plans',
  },
  [`${sharedRouteActions.error404}`]: {
    scene: Error404,
    pageName: 'error_404',
  },
  [`${sharedRouteActions.error500}`]: {
    scene: Error500,
    pageName: 'error_500',
  },
  [`${routeActions.errorBrowser}`]: {
    scene: ErrorBrowser,
    pageName: 'error_browser',
  },
  [`${routeActions.exploreSortingQuiz}`]: {
    scene: ExploreSortingQuiz,
    pageName: 'sorting_quiz',
  },
  [`${routeActions.exploreSortingQuizResults}`]: {
    scene: ExploreSortingQuizResults,
    pageName: 'sorting_quiz_results',
  },
  [`${routeActions.moduleLanding}`]: {
    scene: ModuleLanding,
    pageName: undefined,
  },
  [`${routeActions.path}`]: {
    scene: Path,
    pageName: undefined,
  },
  [`${routeActions.enrolledPathSyllabus}`]: {
    scene: EnrolledPathSyllabus,
    pageName: undefined,
  },
  [`${routeActions.externalOrgPath}`]: {
    scene: ExternalOrgPathLandingPage,
    pageName: undefined,
  },
  [`${routeActions.pathsNew}`]: {
    scene: ChoosePathPage,
    pageName: 'pathselector',
  },
  [`${routeActions.skillPaths}`]: {
    scene: SkillPaths,
    pageName: 'skillpathselector',
  },
  [`${routeActions.subscriptionsCancellationConfirm}`]: {
    scene: CancellationConfirm,
    pageName: 'cancellationpage_confirm',
  },
  [`${routeActions.subscriptionsCancellation}`]: {
    scene: CancellationStart,
    pageName: 'cancellationpage_valueprop',
  },
  [`${routeActions.subscriptionsCancellationSurvey}`]: {
    scene: CancellationSurvey,
    pageName: 'cancellationpage_survey',
  },
  [`${routeActions.subscriptionsTrialExtensionIncentive}`]: {
    scene: CancellationTrialExtensionIncentive,
    pageName: 'cancellationpage_incentive',
  },
  [`${routeActions.subscriptionsCancellationIncentivePromo}`]: {
    scene: CancellationIncentivePromo,
    pageName: 'cancellationpage_incentive_promo',
  },
  [`${routeActions.subscriptionsCheckout}`]: {
    scene: PaymentCheckout,
    pageName: undefined,
  },
  [`${routeActions.subscriptionsPaid}`]: {
    scene: PaymentCheckout,
    pageName: undefined,
  },
  [`${routeActions.subscriptionsPause}`]: {
    scene: PauseSubscription,
    pageName: undefined,
  },
  [`${routeActions.passwordReset}`]: {
    scene: PasswordReset,
    pageName: 'password_reset',
  },
  [`${routeActions.passwordResetRequest}`]: {
    scene: PasswordResetRequest,
    pageName: 'password_reset_request',
  },
  [`${routeActions.passwordResetSent}`]: {
    scene: PasswordResetSent,
    pageName: 'password_reset_sent',
  },
  [`${routeActions.subscriptionsUpgrade}`]: {
    scene: SubscriptionUpgrade,
    pageName: 'subscription_upgrade',
  },
  [`${routeActions.trialExtensionSurvey}`]: {
    scene: TrialExtensionSurvey,
    pageName: undefined,
  },
  [`${routeActions.businessBillingAdmin}`]: {
    scene: BusinessBillingAdmin,
    pageName: 'business_billing_admin',
  },
};
