import React from 'react';

export interface AdvocatorSvgProps {
  className?: string;
}

export const AdvocatorSvg = ({ className }: AdvocatorSvgProps) => (
  <svg className={className} viewBox="0 0 48 48" aria-hidden="true">
    <g strokeWidth="1" fillRule="evenodd">
      <path
        d="M24,1.25 L1.16143452,18.3789241 L9.90725707,47 L38.0927429,47 L46.8385655,18.3789241 L24,1.25 Z"
        strokeWidth="2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
