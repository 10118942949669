export const queryContentModulesById = `
query contentModulesById($id: [ID!]) {
  contentModules(id: $id) {
    id
    title
    slug
    created_at: createdAt
    last_modified_at: updatedAt
    published
    short_description: shortDescription
    long_description: longDescription
    content_item_ids: contentItemIds
    review_card_ids: reviewCardIds
  }
}
`;
