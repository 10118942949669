import { createAction } from 'redux-actions';

import { graphqlGatewayRequest } from '~/libs/graphqlGatewayRequest';
import { selectGraphQLGatewayBaseUrl } from '~/state/apiConfig/selectors';
import { selectUserJWT } from '~/state/currentUser/selectors';
import { selectTrack } from '~/state/entities/tracks/selectors';
import { SiteThunkAction } from '~/state/types';
import { Track } from '~/typings/entities/track';

import { queryTrackData } from './queries';

interface FetchTrackOptions {
  relations?: boolean;
  id?: string | string[];
  slug?: string | string[];
}

interface QueryVariablesV4 {
  relations: boolean;
  id?: string | string[] | null;
  slug?: string | string[] | null;
}

export const readTracksSucceeded = createAction(
  'entities/READ_TRACKS_SUCCEEDED'
);

export const readTracksFailed = createAction('entities/READ_TRACKS_FAILED');

export const fetchTrackDataById = (
  ids: string | string[],
  options?: FetchTrackOptions
): SiteThunkAction<Track[] | {}> => async (dispatch, getState) => {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }
  const store = getState();
  const jwt = selectUserJWT(store);

  const idsToFetch = ids.filter((id) => !selectTrack(store, id));
  if (!idsToFetch.length) return {};

  const url = selectGraphQLGatewayBaseUrl(getState());
  const variables: QueryVariablesV4 = {
    id: idsToFetch,
    slug: null,
    relations: options?.relations || false,
  };
  const { tracks } = (await graphqlGatewayRequest({
    url,
    jwt,
    query: queryTrackData,
    variables,
  })) as TracksResponseV4;

  if (!tracks.length) {
    dispatch(readTracksFailed());
    return {};
  }

  dispatch(readTracksSucceeded(tracks));
  return tracks;
};

export interface TracksResponseV4 {
  tracks: Track[];
}

export const tracksReceived = createAction<Track[]>('entities/TRACKS_RECEIVED');
