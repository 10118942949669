import { merge } from 'lodash';

import { Path } from '~/typings/entities';

export const massageV4PathResponse = (path: Path): Path =>
  merge(
    {
      content_item_type_counts: {
        lesson: path.lesson_count,
        article: path.article_count,
        project: path.project_count,
        quiz: path.quiz_count,
      },
    },
    path
  );
